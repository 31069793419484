import React, { useState, useEffect, useCallback, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import jsPDF from 'jspdf';
import { FaCoffee, FaApple, FaUtensils, FaCookie, FaDumbbell, FaMoon, FaDownload, FaCopy, FaSave, FaTimes } from 'react-icons/fa';
import ErrorCard from '../Error/ErrorCard';
import ErrorCard2 from '../Error/ErrorCard2';
import ErrorCard3 from '../Error/ErrorCard3';
import HamburgerMenuDieta from './MenuDieta/MenuDieta';


const MealSection = ({ title, icon: Icon, content, isVisible, onToggle }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4 transition-all duration-300 ease-in-out">
    <div
      className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 hover:bg-gray-200"
      onClick={onToggle}
    >
      <div className="flex items-center">
        <Icon className="text-gray-600 text-xl mr-2" />
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      </div>
      <span className="text-gray-600">{isVisible ? '▲' : '▼'}</span>
    </div>
    {isVisible && (
      <div className="p-4 bg-white">
        {content.map((line, index) => {
          const cleanLine = line.replace(/\*/g, '').replace(/^#+\s*/, '');
          const isCalories = cleanLine.includes('Calorias') || cleanLine.includes('calórico') || cleanLine.includes('calorias') || cleanLine.includes('calidades') || cleanLine.includes('Calidades');
          const isOption = cleanLine.includes('Opção 1') || cleanLine.includes('Opção 2') || cleanLine.includes('Opção 3');

          return (
            <p key={index} className={`py-2 ${isCalories || isOption ? 'font-semibold' : ''}`}>
              {isCalories ? (
                <span className="text-orange-600">
                  {cleanLine.replace(/Calorias|calórico/g, match => `${match} 🔥`)}
                </span>
              ) : isOption ? (
                <span className="text-blue-600">
                  {cleanLine.replace(/Opção 1|Opção 2|Opção 3/g, match => `${match} 🍽️`)}
                </span>
              ) : (
                cleanLine
              )}
            </p>
          );
        })}
      </div>
    )}
  </div>
);

const ActionButton = ({ icon: Icon, onClick, children }) => (
  <button
    className="flex items-center justify-center px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-300"
    onClick={onClick}
  >
    <Icon className="mr-2" />
    {children}
  </button>
);

export default function LocalDieta() {
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [userDieta, setUserDieta] = useState(null);
  const [userAltura, setUserAltura] = useState(null);
  const [userPeso, setUserPeso] = useState(null);
  const [userImc, setUserImc] = useState('');
  const [info, setInfo] = useState(false)
  const [showError3] = useState(true);
  const [showErro2 ] = useState(true)
  const [showError] = useState(true);
  const [setUserTicketsUsados] = useState(null)

  const [aguaRecomendada, setAguaRecomendada] = useState(0);
  const [mensagem, setMensagem] = useState('');

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibleIndices, setVisibleIndices] = useState([]);
  const [dietaEditavel, setDietaEditavel] = useState([]);
  const [dietaDividida, setDietaDividida] = useState([]);
  const [cafeDaManha, setCafeDaManha] = useState([]);
  const [lancheDaManha, setLancheDaManha] = useState([]);
  const [almoco, setAlmoco] = useState([]);
  const [lancheDaTarde, setLancheDaTarde] = useState([]);
  const [janta, setJanta] = useState([]);
  const [treino, setTreino] = useState([]);

  const { retornaDieta, retornaDados,adicionarDieta,retornaTicketUsado, retirarEdicaoDieta,possuiTicket } = UserAuth();
  const resultTextareaRef = useRef(null);

  const handleToggleElement = (index) => {
    setVisibleIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };



  const calcularAguaNecessaria = useCallback(() => {
    const agua = userPeso ? 0.035 * userPeso : 0;
    setAguaRecomendada(agua);
  }, [userPeso]);

  useEffect(() => {
    calcularAguaNecessaria();
  }, [userPeso, calcularAguaNecessaria]);

  const handleSave = () => {
    adicionarDieta(resultTextareaRef.current.value);
  };

  const copyAnswer = () => {
    if (userDieta) {
      navigator.clipboard.writeText(userDieta)
        .then(() => {
          setSuccessMessageVisible(true);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        })
        .catch(err => {
          console.error('Erro ao copiar para a área de transferência:', err);
        });
    }
  };



  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    retornaDados()
      .then((array) => {
        if (array !== null) {

          setUserPeso(array[0]);
          setUserAltura(array[1]);
          const imcCalculado = array[0] / (array[1] * array[1]);
          if(array[3]){
            setInfo(true)
          }else{
            setInfo(false)
          }
          setUserImc(imcCalculado.toFixed(1));
          if (imcCalculado < 18.5) {
            setMensagem("Você está abaixo do peso");
          } else if (imcCalculado >= 18.5 && imcCalculado <= 24.9) {
            setMensagem("Você está com peso normal");
          } else if (imcCalculado >= 25 && imcCalculado <= 29.9) {
            setMensagem("Você está com sobrepeso");
          } else if (imcCalculado >= 30) {
            setMensagem("Você está com obesidade");
          } else {
            setMensagem("Por favor, atualize seus dados de peso e altura.");

          }

        } else {
          console.log('Sem dados ainda');

        }
      })
      .catch((error) => {
        console.error('Erro ao obter os dados:', error);
      });
  }, [retornaDados]);


      retornaTicketUsado()
      .then((tickets) => {
        if (tickets !== null) {
          setUserTicketsUsados(tickets);

        } else {
          console.log("Nenhum ticket disponível");
        }

      })
      .catch((error) => {
        console.log("Erro ao obter os tickets");
      });

  useEffect(() => {
    retornaDieta()
      .then(async (dieta) => {
        if (dieta !== null) {
          setUserDieta(dieta);
          const dietaDividida = dieta.split(/[\n]+/);
          setDietaDividida(dietaDividida);
          setDietaEditavel(dietaDividida);

          const cafeDaManhaIndex = dietaDividida.findIndex(line =>
            line.includes('Café da Manhã') || line.includes('Café da manhã') || line.includes('CAFÉ DA MANHÃ') || line.includes('Cafe da Manhã') || line.includes('Cafe da manhã')
          );
          const lancheDaManhaIndex = dietaDividida.findIndex(line =>
            line.includes('Lanche da Manhã') || line.includes('Lanche da manhã') || line.includes('LANCHE DA MANHÃ')
          );
          const almocoIndex = dietaDividida.findIndex(line =>
            line.includes('Almoço') || line.includes('almoço') || line.includes('ALMOÇO')
          );
          const lancheDaTardeIndex = dietaDividida.findIndex(line =>
            line.includes('Lanche da Tarde') || line.includes('Lanche da tarde') || line.includes('LANCHE DA TARDE')
          );
          const jantaIndex = dietaDividida.findIndex(line =>
            line.includes('Janta') || line.includes('Jantar') || line.includes('JANTAR') || line.includes('JANTA')
          );
          const treinoIndex = dietaDividida.findIndex(line =>
            line.includes('Treino') || line.includes('treino')
          );

          setCafeDaManha(dietaDividida.slice(cafeDaManhaIndex, lancheDaManhaIndex));
          setLancheDaManha(dietaDividida.slice(lancheDaManhaIndex, almocoIndex));
          setAlmoco(dietaDividida.slice(almocoIndex, lancheDaTardeIndex));
          setLancheDaTarde(dietaDividida.slice(lancheDaTardeIndex, jantaIndex));
          setJanta(treinoIndex === -1 ? dietaDividida.slice(jantaIndex) : dietaDividida.slice(jantaIndex, treinoIndex));
          setTreino(dietaDividida.slice(treinoIndex !== -1 ? treinoIndex : dietaDividida.length));
        } else {
          setUserDieta('');
        }
      })
      .catch((error) => {
        console.error('Erro ao obter a dieta:', error);
        setUserDieta('Erro ao obter a dieta');
      });
  }, [retornaDieta]);

 
  const handleChange = (event) => {
    const newValue = event.target.value.split('\n');
    setDietaEditavel(newValue);
  };

  const handleDownloadPDF = () => {
    if (!userDieta) {
      return;
    }

    const sanitizeText = (text) => {
      return text.replace(/[#@!$%^&*_+=[\]{};'"\\|,<>/?]+/g, '');
    };

    const pdf = new jsPDF();
    const margin = 15;
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    const fontSize = 11;
    const titleFontSize = 24;
    const subtitleFontSize = 16;
    const headingFontSize = 14;
    
    const primaryColor = [29, 158, 34]; // #1D9E22
    const secondaryColor = [0, 102, 204]; // #0066CC
    
    const dado = `Altura: ${userAltura}\nPeso: ${userPeso}\nIMC: ${userImc}\n${mensagem}\nQuantidade de Água Mínima Recomendada: ${aguaRecomendada.toFixed(2)}L`;
    
    const title = 'Facilita Nutri';
    const subtitle = 'Dieta personalizada';
    
    let yPosition = margin;
    
    // Add decorative header
    pdf.setFillColor(...primaryColor);
    pdf.rect(0, 0, pageWidth, 40, 'F');
    
    // Add title
    pdf.setTextColor(255);
    pdf.setFontSize(titleFontSize);
    pdf.setFont('helvetica', 'bold');
    pdf.text(title, pageWidth / 2, 25, { align: 'center' });
    
    // Add subtitle
    pdf.setFontSize(subtitleFontSize);
    pdf.setFont('helvetica', 'normal');
    pdf.text(subtitle, pageWidth / 2, 35, { align: 'center' });
    
    yPosition = 60;
    
    // Add user data
    pdf.setTextColor(0);
    pdf.setFontSize(fontSize);
    pdf.setFont('helvetica', 'bold');
    pdf.text('Seus Dados:', margin, yPosition);
    yPosition += fontSize + 5;
    
    pdf.setFont('helvetica', 'normal');
    const dadoLines = pdf.splitTextToSize(dado, pageWidth - margin * 2);
    dadoLines.forEach(line => {
      pdf.text(line, margin, yPosition);
      yPosition += fontSize + 2;
    });
    
    yPosition += 15;
    
    // Add recommendation
    pdf.setFont('helvetica', 'italic');
    pdf.setTextColor(...secondaryColor);
    pdf.text('Totalmente personalizada para você', margin, yPosition);
    yPosition += fontSize + 15;
    
    // Add diet information
    const dietText = sanitizeText(userDieta);
    const splitText = pdf.splitTextToSize(dietText, pageWidth - margin * 2);
    
    for (let i = 0; i < splitText.length; i++) {
      if (yPosition + fontSize > pageHeight - margin) {
        pdf.addPage();
        yPosition = margin;
        
        // Add decorative header to new page
        pdf.setFillColor(...primaryColor);
        pdf.rect(0, 0, pageWidth, 20, 'F');
      }
    
      pdf.setFontSize(fontSize);
    
      if (splitText[i].includes('Café da Manhã') ||
          splitText[i].includes('Lanche da Manhã') ||
          splitText[i].includes('Café da manhã') ||
          splitText[i].includes('Lanche da manhã') ||
          splitText[i].includes('Almoço') ||
          splitText[i].includes('Lanche da Tarde') ||
          splitText[i].includes('Lanche da tarde') ||
          splitText[i].includes('Janta')) {
        pdf.setTextColor(...primaryColor);
        pdf.setFontSize(headingFontSize);
        pdf.setFont('helvetica', 'bold');
        yPosition += 10;
      } else {
        pdf.setTextColor(0);
        pdf.setFontSize(fontSize);
        pdf.setFont('helvetica', 'normal');
      }
    
      pdf.text(margin, yPosition, splitText[i]);
      yPosition += (pdf.getFontSize() * 1.5);
    }
    
    // Add decorative footer to all pages    
    const fileName = 'Facilita Nutri.pdf';
    pdf.save(fileName);
  }

  const renderMealSection = (title, icon, content, section,treino) => (
    <MealSection
      title={title}
      icon={icon}
      content={content}
      isVisible={visibleIndices.includes(section)}
      onToggle={() => handleToggleElement(section)}


    />
  );


  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-50">
      <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Sua Dieta Personalizada</h1>
      
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-500"></div>
        </div>
      ) : (
        <div className="space-y-6">
        
  
          {userDieta ? (
            edit ? (
              <div className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-800">Modo Edição de Dieta</h2>
                <textarea
                  className="w-full p-4 border border-gray-300 rounded-lg shadow-inner focus:ring-2 focus:ring-gray-400 focus:border-transparent"
                  rows={dietaEditavel.length + 5}
                  value={dietaEditavel.join('\n')}
                  onChange={handleChange}
                  ref={resultTextareaRef}
                />
                <div className="flex justify-center space-x-4">
                  <ActionButton
                    icon={FaSave}
                    onClick={() => {
                      handleSave();
                      setEdit(false);
                      retirarEdicaoDieta();
                      window.location.reload();
                    }}
                  >
                    Salvar
                  </ActionButton>
                  <ActionButton
                    icon={FaTimes}
                    onClick={() => {
                      setEdit(false);
                      setDietaEditavel(dietaDividida);
                    }}
                  >
                    Cancelar
                  </ActionButton>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                {renderMealSection('Café da Manhã', FaCoffee, cafeDaManha, 'breakfast')}
                {renderMealSection('Lanche da Manhã', FaApple, lancheDaManha, 'morningSnack')}
                {renderMealSection('Almoço', FaUtensils, almoco, 'lunch')}
                {renderMealSection('Lanche da Tarde', FaCookie, lancheDaTarde, 'afternoonSnack')}
                {renderMealSection('Janta', FaMoon, janta, 'dinner')}
                {treino.length > 1 && renderMealSection('Treino Personalizado', FaDumbbell, treino, 'workout')}
  
                <div className="flex flex-wrap justify-center gap-4">
                  <ActionButton icon={FaDownload} onClick={handleDownloadPDF}>
                    Baixar PDF
                  </ActionButton>
                
                  <ActionButton icon={FaCopy} onClick={copyAnswer}>
                    Copiar
                  </ActionButton>
                </div>
              </div>
            )
          ) : (
            <>
              {showError3 && !userDieta && possuiTicket && (
                <ErrorCard2 />
              )}
    {showErro2 && userDieta && (
        <ErrorCard3
        />
      )}

  
              {showError && !userDieta && !possuiTicket && info === false && (
                <ErrorCard2
                  id="error1"
             
                />
              )}
  
              {showError && !userDieta && !possuiTicket && info === true && (
                <ErrorCard
                  id="error1"
                />
              )}
            </>
          )}
        </div>
      )}

  
      {successMessageVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl text-center">
            <p className="text-xl font-semibold mb-2 text-gray-800">Dieta copiada com sucesso 🎯</p>
            <p className="text-lg text-gray-600">Agora só colar 📄</p>
          </div>
        </div>
      )}
      <HamburgerMenuDieta></HamburgerMenuDieta>
    </div>
  );
}  