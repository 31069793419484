import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { Menu, X, User, Activity, Calendar, Clipboard, Award, Settings, LogOut } from 'react-feather';
import { motion, AnimatePresence } from 'framer-motion';

function WorkoutSidebar({ isOpen, toggleMenu, workoutStreak }) {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const menuItems = [
    { label: 'Dashboard', icon: <Activity size={20} />, action: () => navigate('/dashboard') },
    { label: 'Plano de Treino', icon: <Calendar size={20} />, action: () => navigate('/treino') },
    { label: 'Exercícios', icon: <Clipboard size={20} />, action: () => navigate('/exercicios') },
    { label: 'Conquistas', icon: <Award size={20} />, action: () => navigate('/conquistas') },
    { label: 'Configurações', icon: <Settings size={20} />, action: () => navigate('/configuracoes') },
    { label: 'Sair', icon: <LogOut size={20} />, action: handleLogout, className: 'text-gray-400 hover:bg-gray-700' },
  ];

  async function handleLogout() {
    try {
      await logout();
      navigate('/');
    } catch (e) {
      console.error('Logout failed', e);
    }
  }

  return (
    <>
      <button
        className="fixed top-4 left-4 z-50 p-2 bg-white rounded-full shadow-lg text-gray-800 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed inset-y-0 left-0 z-40 w-80 bg-gray-900 shadow-xl overflow-y-auto"
          >
            <div className="flex flex-col h-full">
              <div className="flex flex-col items-center justify-center py-8 bg-gray-800">
                <div className="mb-4 p-2 rounded-full bg-gray-700">
                  <User size={48} className="text-gray-300" />
                </div>
                <h3 className="text-xl font-medium text-white">Olá, Atleta!</h3>
                <p className="mt-2 text-sm text-gray-400">{user && user.email}</p>
    
              </div>
              <div className="flex-1 px-4 py-6 mt-6">
                <nav>
                  <ul className="space-y-4">
                    {menuItems.map((item, index) => (
                      <li key={index}>
                        <button
                          onClick={item.action}
                          className={`w-full flex items-center px-4 py-3 text-base font-medium rounded-lg transition-colors duration-150 ease-in-out ${
                            item.className || 'text-gray-300 hover:bg-gray-700'
                          }`}
                        >
                          {item.icon}
                          <span className="ml-3">{item.label}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {isOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-50 transition-opacity"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
}

WorkoutSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  workoutStreak: PropTypes.number,
};

export default WorkoutSidebar;